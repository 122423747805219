<template>
  <div>
    <v-card elevation="2" shaped class="mb-4">
      <v-card-text>
        <div>Data Pegi ke Simak</div>
        <v-form>
          <v-row>
            <v-col class="d-flex" cols="6" sm="4">
              <v-autocomplete
                label="Pilih Tahun"
                :items="years"
                item-value="years"
                v-model="tahun"
                @change="showData"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" md="4">
              <v-menu
                ref="tglmsk"
                v-model="tglmsk"
                :close-on-content-click="false"
                :return-value.sync="tanggalMasuk"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="tanggalMasuk"
                    label="Tanggal Masuk"
                    prepend-icon="fas fa-calendar-alt"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="tanggalMasuk" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="tglmsk = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.tglmsk.save(tanggalMasuk)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" md="4">
              <v-text-field
                v-model="batasStudi"
                label="Batas Studi"
                prepend-icon="fas fa-calendar-alt"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn class="ml-2 mb-5" outlined rounded small @click="sync">
          START SYNC
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card>
      <v-progress-linear :value="skill" striped color="deep-orange" height="25">
        <template>
          <strong>{{ Math.ceil(skill) }}%</strong>
        </template>
      </v-progress-linear>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Data
              </th>
              <th class="text-left">
                Jumlah Data Dikirim
              </th>
              <th class="text-left">
                Berhasil Sync to SIMAK
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Jumlah Data Mahasiswa Baru Dikirim</td>
              <td>{{ Math.ceil(jumlah) }}</td>
              <td>{{ Math.ceil(berhasil) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Symc",
  data: () => ({
    tglmsk: false,
    btsSt: false,
    tess: [],
    skill: 0,
    berhasil: 0,
    jumlah: 0,
    tahun: "",
    tanggalMasuk: new Date().toISOString().substr(0, 10),
    batasStudi: ""
  }),
  computed: {
    ...mapGetters("maba", ["DataMaba"]),
    years() {
      let year = new Date().getFullYear();
      return Array.from(
        {
          length: year - 2018
        },
        (value, index) => 2019 + index
      );
    }
  },
  methods: {
    showData() {
      this.$store.dispatch("maba/loadDataMaba", this.tahun).then(() => {
        let thn = this.tahun + 7;
        this.batasStudi = thn + "2";
        this.jumlah = this.DataMaba.length;
      });
    },
    sync() {
      const datamaba = this.DataMaba;
      this.tess = datamaba.map(e => ({
        kode_perguruan_tinggi: "111008",
        kode_program_studi: e.kode_program_studi,
        nim: e.nim,
        nama_mahasiswa: e.nama_mahasiswa,
        tempat_lahir: e.tempat_lahir,
        tanggal_lahir: e.tanggal_lahir,
        kelas: e.kelas,
        jenis_kelamin: e.jenis_kelamin,
        agama: e.agama,
        alamat: e.alamat,
        status_mahasiswa: "A",
        tahun_masuk: e.tahun_masuk,
        semester_awal: e.tahun_masuk + "1",
        batas_studi: this.batasStudi,
        status_awal_mahasiswa: e.status_awal_mahasiswa,
        mb_ibu_nama: e.mb_ibu_nama,
        email: e.email,
        no_tlp: e.no_tlp,
        nik: e.nik,
        semester: "1",
        tanggal_masuk: this.tanggalMasuk
      }));
      for (let i = 0; i < this.tess.length; i++) {
        this.$store.dispatch("maba/singron", this.tess[i]).then(() => {
          let intval = setInterval(() => {
            if (this.skill < 100) this.skill += parseFloat(i);
            else if (this.berhasil < 100) this.berhasil = this.tess.length;
            else clearInterval(intval);
          }, 200);
        });
      }
    }
  }
};
</script>
